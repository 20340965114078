<template>
    <div
        id="map"
        class="h-full h-[80dvh] overflow-hidden mt-[66px]"
        :class="!isAnimationComplete && 'pointer-events-none'"
    >
        <div class="map-wrapper relative" :class="isLoadingComplete && 'animated'">
            <div class="h-[calc(80vh+9rem)] text-center overflow-hidden">
                <div class="container relative h-full">
                    <div
                        class="w-full lg:w-8/12 mx-auto flex flex-col justify-center items-center z-1 h-full relative text-wrapper opacity-100 pb-28 lg:pb-36 px-4 lg:px-0 pt-8 lg:pt-0"
                    >
                        <div class="font-Poppins font-semibold mb-4 lg:mb-7 text-2xl lg:text-5xl">
                            <T t="generic.loading_screen.title" />
                        </div>
                        <div class="opacity-60 font-Poppins text-sm lg:text-base leading-5 lg:leading-6">
                            <T t="generic.loading_screen.description" />
                        </div>
                        <div class="rounded-full w-16 h-16 bg-yellow-400 p-4 flex items-center justify-center mt-10">
                            <div
                                class="w-full h-full animate-spin"
                                :style="`background-image: url(${require('~/assets/images/icons/loader-icon.svg')})`"
                            ></div>
                        </div>
                    </div>
                    <div
                        class="z-0 absolute bg-contain bg-no-repeat bg-center w-[1000px] h-[1000px] right-[-375px] top-[-650px] sun"
                        :style="`background-image: url(${require('~/assets/images/illustrations/sun.png')})`"
                    ></div>
                    <div
                        class="cloud z-0 absolute bg-contain bg-no-repeat bg-center w-[550px] h-[180px] top-[55%] lg:top-[43%] left-[15%] lg:-left-16 transition-all opacity-100"
                        :style="`background-image: url(${require('~/assets/images/illustrations/cloud2.png')})`"
                    ></div>
                    <div
                        class="cloud2 z-0 absolute bg-contain bg-no-repeat bg-center w-[540px] h-[170px] top-[32%] lg:top-[25%] right-[20%] lg:right-[-15%] scale-x-[-1] transition-all opacity-100"
                        :style="`background-image: url(${require('~/assets/images/illustrations/cloud2.png')})`"
                    ></div>
                    <div
                        class="cloud3 z-0 absolute bg-contain bg-no-repeat bg-center w-[520px] h-[140px] top-[2%] left-[19%] cloud3 scale-x-[-1] transition-all opacity-100"
                        :style="`background-image: url(${require('~/assets/images/illustrations/cloud2.png')})`"
                    ></div>
                    <img
                        :src="require('~/assets/images/logos/liget-logo-10--black.svg')"
                        class="absolute z-1 right-1/2 translate-x-1/2 lg:translate-x-0 lg:right-8 top-16 lg:top-8 max-w-[9rem] lg:max-w-[12rem] lg:mt-0 lg:w-auto"
                        alt="Liget 10 logo"
                    />
                </div>
            </div>
            <div class="relative h-[80dvh] map-inner-wrapper">
                <canvas
                    id="sceneContainer"
                    :class="{
                        'is-dragging': isDragging,
                        'is-draggable': !isDragging || !isClickable,
                        'is-clickable': isClickable,
                    }"
                ></canvas>
                <div v-show="isLoadingComplete">
                    <div
                        v-for="(location, index) in locations"
                        :id="`location_${location.id}`"
                        :key="`location-${location.id}`"
                        class="poi-wrapper absolute flex transition items-center justify-center cursor-pointer -mt-12"
                    >
                        <button
                            class="poi w-10 h-10 rounded-full transition-all scale-0 relative bg-contain bg-center bg-no-repeat group"
                            :class="isAnimationComplete && 'animated'"
                            :style="`animation-delay:${0.05 * index}s`"
                            @click="focusLocation(location.id)"
                        >
                            <span
                                class="h-full absolute bg-contain bg-left bg-no-repeat left-0 top-0 z-[-1] w-max items-center font-bold whitespace-nowrap pl-10 pr-4 rounded-full bg-yellow-400 opacity-0 transition flex group-hover:opacity-100 pointer-events-none"
                            >
                                {{ location.name }}
                            </span>
                        </button>
                    </div>
                </div>
                <Transition name="fade">
                    <div
                        v-if="isMapPlaceCardVisible && currentLocation"
                        class="absolute lg:top-8 max-w-[100vw] lg:max-w-[auto] bottom-8 lg:bottom-[unset] w-96 right-0 lg:right-8 z-4 flex items-end lg:items-center h-max lg:h-auto mt-4 lg:-mt-0 px-2 lg:px-0"
                    >
                        <MapPlaceCard
                            :slug="currentLocation.slug"
                            :title="currentLocation.name"
                            :url="currentLocation?.web_url"
                            :description="currentLocation?.lead_text"
                            :img="currentLocation?.lead_image?.sm"
                            :recommended-program="currentLocation.events[0]"
                            class="h-max"
                            :close="closeMapPlaceCard"
                            is-on3d-map
                        />
                    </div>
                </Transition>
                <div
                    class="flex flex-col transition-all absolute left-3 lg:left-8 top-1/2 -translate-y-1/2 z-2 -mt-20 lg:mt-0"
                    :class="isZooming && 'pointer-events-none opacity-70'"
                >
                    <div @click="resetCameraPosition">
                        <Button v-tooltip="$t('zoom_controls.reset_camera')" class="p-button-sm" icon="pi pi-refresh" />
                    </div>
                    <div @click="zoomIn">
                        <Button
                            v-tooltip="$t('zoom_controls.zoom_in')"
                            :disabled="currentZoomLevel >= 3"
                            class="p-button-sm mt-2"
                            icon="pi pi-plus"
                        />
                    </div>
                    <div @click="zoomOut">
                        <Button
                            v-tooltip="$t('zoom_controls.zoom_out')"
                            :disabled="currentZoomLevel <= 1"
                            class="p-button-sm mt-2"
                            icon="pi pi-minus"
                        />
                    </div>
                    <div
                        v-tooltip="$t('zoom_controls.rotate_camera')"
                        class="flex items-center justify-center mt-3 rounded-full py-4 bg-blue-500 transition hover:bg-blue-400"
                    >
                        <Slider
                            v-model="mapRotation"
                            orientation="vertical"
                            :min="0"
                            :max="360"
                            class="map-rotation-slider"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full absolute flex justify-center md:hidden -bottom-5">
            <a
                href="#search-section-anchor"
                class="transition-all block bg-yellow-400 hover:bg-yellow-500 w-10 h-10 rounded-full delay-500 shadow-md opacity-0"
                :class="isLoadingComplete && 'opacity-100'"
            >
                <i class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pi pi-chevron-up rotate-180"></i>
            </a>
        </div>
    </div>
</template>

<script>
import locationsData from './map3DLocations.json';
import MapPlaceCard from '@/components/Cards/MapPlaceCard/MapPlaceCard.vue';

export default {
    name: 'Map3D',
    components: {
        MapPlaceCard,
    },
    data() {
        return {
            map: null, // 3D map object
            sizes: null, // Dimensions of the rendering area
            scene: null, // Three.js scene
            camera: null, // Three.js camera
            isDragging: false, // Flag indicating if the map is being dragged
            isClickable: false, // Flag for clickable interactions (not used currently)
            currentZoomLevel: 1, // Current zoom level of the map
            isZooming: false, // Flag indicating if the map is zooming
            isLoadingComplete: false, // Flag indicating if the map loading is complete
            isAnimationComplete: false, // Flag indicating if the initial camera animation is complete
            isMapPlaceCardVisible: false, // Flag indicating if the location card is visible
            cameraPosition: null, // Current camera position
            mapRotation: 0, // Current rotation angle of the map
            previousRotationAngle: 0, // Previous rotation angle for smooth rotation
            controls: null, // Three.js map controls
            renderer: null, // Three.js renderer
            minPan: {
                // Minimum panning boundaries
                x: -9,
                y: -9,
                z: -9,
            },
            maxPan: {
                // Maximum panning boundaries
                x: 9,
                y: 9,
                z: 9,
            },
            minPanObj: null, // Three.js Vector3 for minPan
            maxPanObj: null, // Three.js Vector3 for maxPan
            newCameraPosition: null, // Temporary camera position during panning
            initialCameraPosition: {
                // Initial camera position
                x: 0.01666798549582887,
                y: 5.449373731944013,
                z: -20.144884086244073,
            },
            initialCameraRotation: {
                // Initial camera rotation (not used currently)
                x: 0,
                y: 0,
                z: 0,
            },
            currentLocation: null, // Currently focused location object
            animationFrameId: null, // ID of the animation frame for rendering
            locations: [], // Array of location objects
        };
    },
    watch: {
        isLoadingComplete(loaded) {
            if (loaded) {
                setTimeout(() => this.camera && this.setCameraToInitialPosition(), 1750);
                setTimeout(() => (this.isAnimationComplete = true), 3000);
            }
        },
        currentZoomLevel(value) {
            this.currentZoomLevel = Math.min(Math.max(value, 1), 3); // Clamp zoom level between 1 and 3
        },
        mapRotation(angle) {
            this.rotateMap(angle, this.currentLocation);
        },
    },
    mounted() {
        // Fetch location data from API and merge with local JSON data
        this.fetchLocations();

        // Initialize Three.js components
        this.initScene();

        // Event listeners
        this.initEventListeners();
    },
    beforeDestroy() {
        // Remove event listeners to prevent memory leaks
        window.removeEventListener('resize', this.handleResizeEvent);

        // Dispose of Three.js objects to free up memory
        if (this.map) {
            this.map.traverse((object) => {
                if (object.isMesh) {
                    object.geometry.dispose();
                    object.material.dispose();
                }
            });
            // Remove the model from the scene
            const scene = this.map.parent;
            if (scene) {
                scene.remove(this.map);
            }
        }

        // Stop the animation loop
        window.cancelAnimationFrame(this.animationFrameId);
    },
    methods: {
        initScene() {
            const canvas = document.querySelector('#sceneContainer');
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight * 0.8,
            };

            const renderer = new this.$THREE.WebGLRenderer({ canvas, antialias: false });
            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(3);

            const camera = new this.$THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.5);
            camera.position.copy(
                new this.$THREE.Vector3(
                    this.initialCameraPosition.x,
                    this.initialCameraPosition.y,
                    this.initialCameraPosition.z
                )
            );
            camera.zoom = 1.5;

            const scene = new this.$THREE.Scene();
            scene.background = new this.$THREE.Color(0xffffff);
            scene.add(camera);

            const controls = new this.$MapControls(camera, renderer.domElement);
            Object.assign(controls, {
                enableDamping: true,
                dampingFactor: 0.05,
                enableZoom: false,
                screenSpacePanning: false,
                maxPolarAngle: Math.PI / 2.15,
            });

            const raycaster = new this.$THREE.Raycaster();
            const mouse = new this.$THREE.Vector2();
            const poiPosition = new this.$THREE.Vector3();
            let intersects = [];

            // Load 3D model
            const loader = new this.$GLTFLoader();
            loader.setDRACOLoader(new this.$DRACOLoader().setDecoderPath(`scripts/3d-transcoder/draco/`));
            loader.setKTX2Loader(
                new this.$KTX2Loader().setTranscoderPath('scripts/3d-transcoder/').detectSupport(renderer)
            );

            loader.load('webterkep_05_07_png.glb', (gltf) => {
                this.map = gltf.scene;
                this.map.position.y = 0;
                this.map.position.x = -1.2;
                scene.add(this.map);
                intersects = raycaster.intersectObjects(scene.children, true);
                this.isLoadingComplete = true;
            });

            // Animation loop
            const update = () => {
                controls.update();
                renderer.render(scene, camera);
                this.setPoiPositions(raycaster, mouse, camera, canvas, scene, poiPosition, intersects);
                this.animationFrameId = window.requestAnimationFrame(update);
            };

            this.animateZoom(0.5, 'in');
            update();

            // Set global variables
            this.camera = camera;
            this.controls = controls;
            this.renderer = renderer;
            this.scene = scene;
            this.sizes = scene;
            this.newCameraPosition = new this.$THREE.Vector3();
            this.minPanObj = new this.$THREE.Vector3();
            this.maxPanObj = new this.$THREE.Vector3();
        },
        fetchLocations() {
            this.$axios
                .$get('/map/3d')
                .then((res) => {
                    this.locations = res.data.reduce((acc, location, i) => {
                        const l = locationsData.data.find((x) => x.external_id === location.external_id);

                        if (!l) {
                            return acc;
                        }

                        Object.keys(l).forEach((key) => {
                            location[key] = l[key];
                        });

                        acc.push(location);

                        return acc;
                    }, []);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        initEventListeners() {
            window.addEventListener('resize', () => {
                this.handleResizeEvent(this.sizes, this.camera, this.renderer);
            });

            const canvas = document.querySelector('#sceneContainer');
            canvas.addEventListener('mousedown', () => {
                this.isDragging = true;
                this.getCameraPositions(this.camera.position, this.controls.target);
            });

            canvas.addEventListener('mouseup', () => {
                this.isDragging = false;
            });

            this.controls.addEventListener('change', () => {
                this.handleDraggableArea(this.controls, this.camera);
            });
        },
        rotateMap(angle, pivot) {
            // Calculate the pivot point for rotation
            const locationPivot = {
                x: pivot?.rotationPosition.x - 1.25,
                y: pivot?.rotationPosition.y,
                z: pivot?.rotationPosition.z,
            };

            // Determine the target point for rotation
            const target = this.currentLocation ? locationPivot : this.controls.target;

            // Calculate the rotation angle and direction
            const rotationDirection = angle >= this.previousRotationAngle ? 1 : -1;
            const rotationAmount = Math.abs(angle - this.previousRotationAngle);
            const rotationSpeed = Math.PI / 180;
            const rotationAngle = rotationDirection * rotationSpeed * rotationAmount;

            // Rotate the camera around the target
            const relativePosition = this.camera.position.clone().sub(target);

            const newX = relativePosition.x * Math.cos(rotationAngle) - relativePosition.z * Math.sin(rotationAngle);
            const newZ = relativePosition.x * Math.sin(rotationAngle) + relativePosition.z * Math.cos(rotationAngle);

            this.camera.position.x = target.x + newX;
            this.camera.position.z = target.z + newZ;

            this.camera.lookAt(target);

            this.controls.target.copy(target);
            this.controls.update();
            this.previousRotationAngle = angle;
        },
        focusLocation(id) {
            const poi = document.getElementById(`location_${id}`);
            poi.style.transform = 'scale(0)';

            const location = this.locations.find((location) => location.id === id);

            if (!location) {
                return;
            }

            this.currentLocation = location;
            this.mapRotation = 0;
            this.isMapPlaceCardVisible = true;
            this.isZooming = true;
            this.isDragging = true;

            // Set camera controls and target position
            this.setCameraControls(location.camera.controls.x, location.camera.controls.y, location.camera.controls.z);

            // Use GSAP to animate camera zoom and position simultaneously
            this.$gsap.to(this.camera, {
                duration: 1,
                zoom: 1.5,
                onUpdate: () => this.camera.updateProjectionMatrix(), // Update projection matrix on each frame
            });

            this.$gsap.to(this.camera.position, {
                duration: 1,
                x: location.camera.position.x,
                y: location.camera.position.y,
                z: location.camera.position.z,
                onUpdate: () => {
                    const zoomOffset = 2.5 * this.currentZoomLevel;
                    this.maxPan.x = this.maxPan.y = this.maxPan.z = this.maxPan.x + zoomOffset;
                    this.minPan.x = this.minPan.y = this.minPan.z = this.minPan.x - zoomOffset;
                },
                onComplete: () => {
                    this.isZooming = false;
                    this.isDragging = false;
                    this.currentZoomLevel = 2;
                    this.maxPan = { x: 22, y: 22, z: 22 };
                    this.minPan = { x: -22, y: -22, z: -22 };
                },
            });
        },
        setCameraToInitialPosition() {
            // Define and set the initial camera position
            const cameraPositions = {
                x: 0.0749765738056184,
                y: 16.225789343631302,
                z: -14.03721510752926,
            };
            this.isDragging = true;
            this.$gsap.to(this.camera.position, {
                duration: 2,
                ease: 'Power6.easeOut',
                x: cameraPositions.x,
                y: cameraPositions.y,
                z: cameraPositions.z,
                onComplete: () => {
                    this.initialCameraPosition = {
                        x: cameraPositions.x,
                        y: 16.225789343631302,
                        z: -14.03721510752926,
                    };

                    this.maxPan = {
                        x: 10,
                        z: 10,
                        y: 10,
                    };
                    this.minPan = {
                        x: -10,
                        z: -10,
                        y: -10,
                    };

                    this.isDragging = false;
                },
            });
        },
        getCameraPositions(camera, controls) {
            // Log camera and controls information in development mode
            if (this.$config.nodeENV === 'development') {
                console.log(camera);
                console.log(controls);
            }
        },
        setPoiPositions(raycaster, mouse, camera, canvas, scene, poiPosition, intersects) {
            raycaster.setFromCamera(mouse, camera);

            for (const location of this.locations) {
                const poi = document.getElementById(`location_${location.id}`);
                const selectedBuilding = intersects.find((obj) => obj.object.name === location.id);

                if (selectedBuilding) {
                    poiPosition.setFromMatrixPosition(selectedBuilding.object.matrixWorld);
                    poiPosition.project(camera);

                    location.rotationPosition = selectedBuilding.object.position;

                    const widthHalf = canvas.width / 2;
                    const heightHalf = canvas.height / 2;

                    poiPosition.x = poiPosition.x * widthHalf + widthHalf;
                    poiPosition.y = -(poiPosition.y * heightHalf) + heightHalf;

                    poi.style.top = `${poiPosition.y / 3}px`;
                    poi.style.left = `${poiPosition.x / 3}px`;

                    // // Calculate the 3D distance in one line using Math.hypot
                    // const totalDistance = Math.hypot(
                    //     camera.position.x - selectedBuilding.object.position.x,
                    //     camera.position.y - selectedBuilding.object.position.y,
                    //     camera.position.z - selectedBuilding.object.position.z
                    // );

                    // // Opacity ranges from 0.4 (40%) to 1 (100%)
                    // const maxDistance = 30;
                    // const minOpacity = 0.1;
                    // const maxOpacity = 1;
                    // const opacity =
                    //     minOpacity + (1 - totalDistance / maxDistance) * (maxOpacity * 2.25 - minOpacity / 4);
                    //
                    // poi.style.opacity = Math.min(maxOpacity, Math.max(minOpacity, opacity)).toFixed(2);
                }
            }
        },
        closeMapPlaceCard() {
            // Hide the location card and reset camera position
            this.isMapPlaceCardVisible = false;
            this.resetCameraPosition();
        },
        handleDraggableArea(controls, camera) {
            // Constrain camera movement within the defined boundaries
            this.minPanObj.set(this.minPan.x, this.minPan.y, this.minPan.z);
            this.maxPanObj.set(this.maxPan.x, this.maxPan.y, this.maxPan.z);
            this.newCameraPosition.copy(controls.target);
            controls.target.clamp(this.minPanObj, this.maxPanObj);
            this.newCameraPosition.sub(controls.target);
            camera.position.sub(this.newCameraPosition);
        },
        resetCameraControls() {
            // Reset camera controls to the initial state
            this.isDragging = true;
            this.isZooming = true;
            this.mapRotation = 0;
            this.currentLocation = null;
            this.rotateMap(0);

            this.$gsap.to(this.controls.target, {
                duration: 1,
                x: 0,
                y: 0,
                z: 0,
                onUpdate: () => {
                    this.controls.update();
                },
                onComplete: () => {
                    this.isDragging = false;
                    this.isZooming = false;
                },
            });
        },
        showPois() {
            const poiElements = document.getElementsByClassName('poi-wrapper');
            Array.from(poiElements).forEach((element) => {
                element.style.transform = 'scale(1)';
            });
        },
        setCameraControls: function (x, y, z) {
            // Set camera controls to specific coordinates
            this.isDragging = true;
            this.isZooming = true;

            this.$gsap.to(this.controls.target, {
                duration: 1,
                x,
                y,
                z,
                onUpdate: () => {
                    this.controls.update();
                },
                onComplete: () => {
                    this.isDragging = false;
                    this.isZooming = false;
                },
            });
        },
        resetCameraPosition() {
            // Reset camera position to the initial state
            this.isDragging = true;

            this.showPois();

            this.resetCameraControls();

            this.$gsap.to(this.camera, {
                duration: 0.5,
                zoom: 2,
            });

            this.$gsap.to(this.camera.position, {
                duration: 0.5,
                x: this.initialCameraPosition.x,
                y: this.initialCameraPosition.y,
                z: this.initialCameraPosition.z,
                onUpdate: () => {
                    this.camera.updateProjectionMatrix();
                },
                onComplete: () => {
                    this.maxPan = {
                        x: 9,
                        z: 9,
                        y: 9,
                    };
                    this.minPan = {
                        x: -9,
                        z: -9,
                        y: -9,
                    };

                    this.currentZoomLevel = 1.5;

                    this.isDragging = false;
                    this.isMapPlaceCardVisible = false;
                },
            });
        },
        handleResizeEvent(sizes, camera, renderer) {
            // Update camera and renderer settings on window resize
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;

            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();
            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(3);
        },
        zoomIn() {
            // Animate zoom in
            this.animateZoom(1, 'in');
        },
        zoomOut() {
            // Animate zoom out
            this.animateZoom(-1, 'out');
        },
        animateZoom(delta, direction) {
            // Perform zoom animation with specified delta and direction
            this.isZooming = true;

            // Calculate target zoom level, clamped between 1 and 3
            const targetZoom = Math.min(Math.max(this.currentZoomLevel + delta, 1), 3);

            // Determine zoom parameters based on whether a location is focused
            const zoomLevel = this.currentLocation ? this.currentZoomLevel + 0.1 : targetZoom;
            const yOffset = this.currentLocation ? 0.02 : 0.06;
            const baseOffset = direction === 'in' ? 12 : 8;

            this.$gsap.to(this.camera, {
                duration: 0.5,
                zoom: zoomLevel,
                onUpdate: () => {
                    // Adjust camera's vertical position based on zoom direction
                    this.camera.position.y += direction === 'in' ? -yOffset : yOffset;

                    // Calculate and update pan boundaries dynamically
                    const zoomOffset = 2.5 * this.currentZoomLevel;
                    const maxPanValue = baseOffset + zoomOffset;
                    const minPanValue = -baseOffset - zoomOffset;

                    this.maxPan.x = this.maxPan.y = this.maxPan.z = maxPanValue;
                    this.minPan.x = this.minPan.y = this.minPan.z = minPanValue;

                    this.camera.updateProjectionMatrix();
                },
                onComplete: () => {
                    this.currentZoomLevel = targetZoom;

                    // Reset pan boundaries if zoom level is 1
                    if (this.currentZoomLevel === 1) {
                        this.maxPan.x = this.maxPan.y = this.maxPan.z = 9;
                        this.minPan.x = this.minPan.y = this.minPan.z = -9;
                    }
                    this.isZooming = false;
                },
            });
        },
        scrollToSection(section) {
            // Smooth scroll to a specified section ID
            const targetSection = document.getElementById(section);

            if (targetSection) {
                const offsetTop = targetSection.offsetTop;

                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style scoped>
.poi {
    @keyframes popIn {
        0% {
            transform: scale(0);
        }
        80% {
            transform: scale(1.25);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes popInLg {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.25);
        }
    }

    background-image: url('~/static/demo-map-icons/location.png');

    span {
        background-image: url('~/static/demo-map-icons/location.png');
    }

    &.animated {
        animation-name: popIn;
        animation-duration: 0.25s;
        animation-fill-mode: forwards;
    }
}

.poi-wrapper {
    @keyframes fadeOutPoiWrapper {
        from {
            opacity: 0;
        }
        to {
            opacity: 100%;
        }
    }
    &:hover {
        opacity: 1 !important;
    }
    &.fade-out {
        animation: fadeOutPoiWrapper 0.25s forwards;
    }
}

.is-draggable {
    cursor: grab;
}

.is-dragging {
    cursor: grabbing;
}

.is-clickable {
    cursor: pointer;
}

.button--primary:hover,
.shopify-payment-button__button:hover {
    transition: 0.25s ease;
    background-color: #d8c300 !important;
}

.button--secondary {
    border: 1px solid #fbe200;
}

.h-max {
    height: max-content;
}

.map-wrapper {
    @apply transition-all bg-[#e1e1e1];
    @keyframes slide {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(calc(-80vh - 9rem));
        }
    }
    @keyframes slideToUp {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(-10rem);
        }
    }
    @keyframes slideToLeft {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(20rem);
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes slideToRight {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-20rem);
        }
        100% {
            transform: translateX(0);
        }
    }

    .sun {
        @apply transition-all translate-y-0;
        animation-name: slideToUp;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in;
    }

    .cloud {
        @apply transition-all translate-x-0;
        animation-name: slideToRight;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in;
    }

    .cloud2 {
        @apply transition-all translate-x-0;
        animation-name: slideToLeft;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in;
    }

    .cloud3 {
        @apply transition-all translate-x-0;
        animation-name: slideToRight;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in;
    }

    transform: translateY(0);

    &.animated {
        animation-name: slide;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
        animation-delay: 0.5s;

        .text-wrapper {
            @apply -translate-y-20 opacity-0;
            transition-duration: 0.9s;
            transition-delay: 0.4s;
        }

        .sun {
            @apply -translate-y-20 opacity-0;
            transition-duration: 0.9s;
            transition-delay: 0.4s;
        }

        .cloud {
            @apply -translate-x-32 opacity-0;
            transition-duration: 2s;
        }

        .cloud2 {
            @apply translate-x-32 opacity-0;
            transition-duration: 2s;
        }

        .cloud3 {
            @apply -translate-x-32 opacity-0;
            transition-duration: 2s;
        }
    }
}

.map-inner-wrapper {
    &::before {
        content: '';
        @apply h-36 w-full -top-36 left-0 absolute;
        background-image: linear-gradient(180deg, rgba(237, 242, 231, 0) 0%, #fff 100%);
    }
}

.animate-spin {
    animation-duration: 1.5s;
}

.map-rotation-slider {
    .p-slider-range,
    .p-slider {
        background: white !important;
    }
}
</style>
